// Generic

export const required = (value) => value && value !== "" && value.length >= 1;

export const requiredBool = (value) => {return value};

export const email = value => {
    const emailTest = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailTest.test(value)
}

export const phone = value => {
    const phoneTest = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
    return phoneTest.test(value)
}

export const number = value => {
    const numberTest = /^[0-9]+$/;
    return numberTest.test(value)
}

export const password = value => {

    // min 8 letter password, with at least a symbol, upper and lower case letters and a number
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    // min 8 characters, at least one letter and one number
    //var re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    return re.test(value);
}


export const postcode = value => {
    var valid = new RegExp(/^\d{4}$/);
    return valid.test(value);
}

export const ABN = value => {
    var valid = new RegExp(/^(\d *?){11}$/);
    return valid.test(value);
}

// Exclude shipping fields from validation if not used

export const excludeShipping = (validator, value, fields) => {
    if (fields && fields.useBillingForShipping.value) return true
    return validator ? validator(value) : true
}